import Cookies from 'js-cookie';
import { on, fire } from 'delegated-events';

import initObserver from './observer';

//document.addEventListener('consent:mandatory', function (e) {
//    console.debug('mandatory fired, with valuue',e.detail);
//});

document.addEventListener('consent_change', function (e) {
    console.log('consent_change detected');
    if (typeof gtag === 'undefined')
        return;

    var updateItem = {
        ad_storage: window.consent_table["marketing"] ? 'granted' : 'denied',
        ad_user_data: window.consent_table["marketing"] ? 'granted' : 'denied',
        ad_personalization: window.consent_table["marketing"] ? 'granted' : 'denied',
        analytics_storage: window.consent_table["analytics"] ? 'granted' : 'denied',
        functionality_storage: window.consent_table["mandatory"] ? 'granted' : 'denied',
        security_storage : window.consent_table["mandatory"] ? 'granted' : 'denied'
    }

    console.log('constent update GA4 Item', updateItem);
    gtag('consent', 'update', updateItem);
});

export default function () {
    const privacySettings = window.privacySettings;

    if (!privacySettings)
        return;

    const COOKIE_PRIVACY_NAME = 'gs_privacy_preferences';
    const {
        links, description, consents
    } = privacySettings;
    const privacyBox = document.createElement('div');

    privacyBox.classList.add('privacy-box');

    const logoUrl = document.querySelector('.logo img').getAttribute('src') || "";
    const consentsHtml = consents && consents.length > 0 ? `
            <ul class="privacy-box__consents">
                ${consents.map(consent => {
        if (consent.name === '') return;

        return `<li>
                <div class="consent-detail ${consent.description.length > 0 ? "toggleable" : ""}">
                    <div class="consent-detail__header">
                        <strong class="${consent.mandatory ? "default" : ""}">${consent.name}</strong>

                        ${consent.mandatory ? "<span>wymagane</span>" : ""}

                        <label for="${consent.key}" class="consent-detail__checker ${consent.mandatory ? "mandatory" : ""}">
                            <input id=${consent.key} type="checkbox" ${consent.mandatory ? 'checked' : ''} />
                            <div></div>
                        </label>
                    </div>

                    ${consent.description.length > 0 ? `
                        <div class="consent-detail__description"><p>${consent.description}</p></div>
                    ` : ""}
                </div>
            </li>`;
    }).join('')}
            </ul>` : "";

    const linksHtml = links && links.length > 0 ? `
            <ul class="privacy-box__links">
                ${links.map(link => {
        return `<li><a href="${link.link}">${link.anchor}</a></li>`;
    }).join('')}
            </ul>
        ` : "";

    privacyBox.innerHTML = `
        <div class="privacy-box__header">
            ${logoUrl.length > 0 ? `<img src="${logoUrl}" alt="" />` : ""}
            <strong>Zarządzaj zgodami plików cookie</strong>
        </div>
        <div class="privacy-box__wrapper">
            ${description.length > 0 ? `<p class="privacy-box__description">${description}</p>` : ""}
            ${consentsHtml}
        </div>
        <div class="privacy-box__actions">
            <button class="privacy-reject-all">Odmów</button>
            <button class="privacy-preferences">Preferencje</button>
            <button class="privacy-accept-all">Akceptuj wszystkie</button>
        </div>
        ${linksHtml}
    `;

    const createPrivacyBox = () => {
        document.body.appendChild(privacyBox);
        document.body.classList.add('privacy-box-visible');
    }

    const showPrivacyBoxTrigger = () => {
        const privacyBoxTrigger = document.querySelector('.privacy-box-trigger');

        if (privacyBoxTrigger) {
            privacyBoxTrigger.classList.add('show');

            privacyBoxTrigger.addEventListener('click', function () {
                createPrivacyBox();
                privacyBoxTrigger.classList.remove('show');
            });
        }
    }

    const dispatchConsentsEvents = () => {
        const allConsents = [...privacyBox.querySelectorAll('.privacy-box__consents input')].filter(consent => consent.getAttribute('id') !== 'mandatory');
        window.consent_table = [];
        window.consent_table['mandatory'] = true;

        allConsents.forEach(consent => {
            const consentType = consent.getAttribute('id');
            const accepted = consent.checked;

            fire(document.body, `consent:${consentType}`, { accepted });
        });

        allConsents.forEach(item => {
            window.consent_table[item.getAttribute('id')] = item.checked;
        });

        fire(document.body, 'consent_change', {
            consents: window.consent_table
        });
    }

    const currentPreferences = Cookies.get(COOKIE_PRIVACY_NAME);

    if (currentPreferences) {
        const consentEventsList = JSON.parse(currentPreferences).consents;

        consentEventsList.forEach(consent => {
            const checker = privacyBox.querySelector(`input[type="checkbox"]#${consent}`);
            if (checker) {
                checker.checked = true;
            } else {
                return;
            }

            const consentType = checker.getAttribute('id');
            const accepted = checker.checked;

            fire(document.body, `consent:${consentType}`, { accepted });
        });

        showPrivacyBoxTrigger();
        dispatchConsentsEvents();
    } else {
        // COOKIE OBSERVER
        const cookieSentinel = document.querySelector('.privacy-sentinel');

        initObserver(cookieSentinel, function () {
            setTimeout(function () {
                createPrivacyBox();
            }, 100);
        });
    }

    const consentsList = [...privacyBox.querySelectorAll('input[type="checkbox"]')];

    const saveUserPreferences = () => {
        const cookieObject = {
            date: new Date(),
            consents: [...consentsList].filter(consent => consent.checked).map(consent => {
                return consent.getAttribute('id');
            })
        }

        Cookies.set(COOKIE_PRIVACY_NAME, JSON.stringify(cookieObject), { expires: 365 });
        privacyBox.remove();
        document.body.classList.remove('privacy-box-visible');
        showPrivacyBoxTrigger();
        dispatchConsentsEvents();
    }

    on('click', '.privacy-box .toggleable .consent-detail__header > strong', function (e) {
        this.parentElement.parentElement.classList.toggle('show');
    });

    on('click', '.privacy-box__actions button', function () {
        const buttonType = this.classList[0];

        switch (buttonType) {
            case 'privacy-reject-all':
                consentsList.filter(consent => consent.getAttribute('id') !== 'mandatory').forEach(consent => consent.checked = false);
                saveUserPreferences();
                break;

            case 'privacy-preferences':
                if (privacyBox.classList.contains('preferences-visible')) {
                    saveUserPreferences();
                }

                break;

            case 'privacy-accept-all':
                consentsList.forEach(consent => consent.checked = true);
                saveUserPreferences();
                break;

            default:
                break;
        }
    });

    on('click', '.privacy-box:not(.preferences-visible) .privacy-preferences', function () {
        privacyBox.classList.add('preferences-visible');
        this.textContent = 'Zapisz preferencje';
    });

}
