import { on } from 'delegated-events';

import openModal from './modals';
import initRecaptcha from './recaptcha';

export default function() {
    const newsletter = document.getElementById('newsletter');

    if (newsletter) {
        newsletter.querySelector('.popup-trigger').addEventListener('click', function () {
            openModal(newsletter.querySelector('.goshop-modal-content').innerHTML);

            initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
        });

        newsletter.querySelector('input[type="text"]').addEventListener('keyup', function(e) {
            if (e.key === "Enter" || e.keyCode === 13) {
                openModal(newsletter.querySelector('.goshop-modal-content').innerHTML);

                initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
            }
        });

        on('click', '.goshop-modal .newsletter-clauses-wrapper ~ button[type="submit"]', function (e) {
            const checkboxes = document.querySelector('.goshop-modal').querySelectorAll('[type="checkbox"]:checked');

            if (checkboxes.length !== 1) {
                document.querySelector('.goshop-modal .newsletter-clauses-wrapper').classList.add('unchecked');
                e.preventDefault();
            } else {
                window.currentModalInstance.close();

                const recaptchaItem = document.querySelector('.goshop-modal .g-recaptcha-response');

                fetch('/api/frontend/newsletter', {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        email: newsletter.querySelector('input[type="text"]').value,
                        recaptcha: recaptchaItem ? recaptchaItem.value : ''
                    }),
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                })
                .then(response => response.json())
                .then(data => {
                    newsletter.querySelector('input[type="text"]').value = '';
                    openModal(data.message);
                })
                .catch(error => {
                    newsletter.querySelector('input[type="text"]').value = '';
                    console.log(error);
                });
            }
        });
    }
}