import { isVisible } from './utils/utils';

export default function () {
    const sorters = document.querySelectorAll('.sorter');

    sorters.forEach(sorter => {
        document.addEventListener('click', function (event) {
            const element = sorter;

            if (!element.contains(event.target) && isVisible(element)) {
                element.classList.remove('show-dropdown');
            }
        });

        const selectedLabelItem = sorter.querySelector('input[type="radio"]:checked + label');
        const selectedSpanText = selectedLabelItem ? selectedLabelItem.textContent : '---';
        const selectedButton = sorter.querySelector('.sorter-button');

        selectedButton.textContent = selectedSpanText;

        selectedButton.addEventListener('click', function () {
            this.parentElement.classList.toggle('show-dropdown');
        });
    });
}