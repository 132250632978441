import { isVisible } from './utils/utils';

function closeFunctionModal(item) {
    item.classList.remove('show');

    if (window.grecaptcha)
        window.grecaptcha.reset();
}

export default function () {
    const functionModals = document.querySelectorAll('.function-modal');

    functionModals.forEach(modal => {
        modal.querySelector('.close').addEventListener('click', function () {
            closeFunctionModal(modal);
        });
    });

    if (functionModals.length > 0) {
        document.addEventListener('mouseup', function (event) {
            const elements = document.querySelectorAll('.function-modal.show .function-modal-inner');
            
            elements.forEach(elem => {
                if (!elem.contains(event.target) && isVisible(elem)) {
                    closeFunctionModal(elem.parentElement);
                }
            });
        });
    }
}